.TextArea
{
    height: 5rem;
    width: 40%;
    font-size: 1rem;
    background: transparent;
    border: none;
    border: 2px solid white; 
    color: white;
    box-shadow: none;
    resize: none;
    padding: 5px;
}

.TextArea::placeholder
{
    color: grey;
}

.TextArea:focus
{
    outline: none;
}

@media(max-width: 1000px)
{
    .TextArea
    {
        font-size: 0.8rem;
    }
    .TextArea::placeholder
    {
        font-size:0.8rem;
    }
}