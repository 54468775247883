.ContactPageDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh !important;
    background: blue;
    margin: 0 !important;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../assets/Images/sendCommentBackground.jpg");
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
}

.ContactDiv
{
    width: 50%;
    height: 70vh;
    background: #04396C;
    z-index: 10;
    border: 6px solid #0B9CEA;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.ContactHeader
{
    color: yellowgreen;
    font-size: 2rem;
}

.ContactDiv::-webkit-scrollbar {
    width: 0;
}

.ContactDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media (max-width: 1000px)
{
    .ContactDiv
    {
        width: 70%;
        height: auto;
        overflow: scroll;
    }

    .ContactHeader
    {
        margin-top: 20%;
        margin-bottom: 1rem;
        font-size: 1.5rem;
    }
}