.Container
{
    height: 100vh;
    max-height: 100vh !important;
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
}

.Container::-webkit-scrollbar {
    width: 0;
}

.Container::-webkit-scrollbar-thumb {
    background-color: transparent;
}