.ProjectDivContainer
{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background: transparent;
    align-items: center;
    justify-content:space-evenly;
}

@media (max-width: 1000px)
{
    .ProjectDivContainer
    {
        height: auto;
        flex-direction: column;
    }
}