.Input
{
    height: 1.2rem;
    width: 40%;
    font-size: 1rem;
    background: transparent;
    border: none;
    border-bottom: 2px solid white; 
    color: white;
    box-shadow: none;
    padding: 5px;
}

.Input::placeholder
{
    color: grey;
}

.Input:focus
{
    outline: none;
}

@media(max-width: 1000px)
{
    .Input
    {
        font-size: 0.8rem;
    }
    .Input::placeholder
    {
        font-size:0.8rem;
    }
}