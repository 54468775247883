.PersonelName
{
    font-size: 1.8rem;
    font-weight: 500;
    width: 100%;
    font-family: "Parisienne";
    color: white;
    margin-top: 2rem;
}

@media (max-width: 500px)
{
    .PersonelName
    {
        font-size: 1.2rem;
    }
}