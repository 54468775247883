.CVFeature
{
    font-size: 1.1rem;
    font-weight: bold;
    color: #0f5a7c;
    margin: 0.5rem 1rem;
}


.FeatureLevel
{
    font-weight: bold;
    color: #0275d8;
}