.Icon
{
    margin-top: 1rem;
    color: white;
    font-size: 1.5rem;
}

.Icon:hover
{
    color: #0275d8;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}
