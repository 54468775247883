.AboutPageDiv
{
    width: 100%;
    height: 100%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../assets/Images/aboutBackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.AboutPageInnerDiv
{
    width:90%;
    height:90%;
    background: transparent;
    margin-top: 5%;
}

.AboutHeader
{
    font-size: 2rem;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: white;
    margin-bottom: 1%;
}

.AboutDescription
{
    font-size:1.3rem;
    color:white;
    font-weight: 400;
}

.AboutInfoDiv
{
    margin-top:2%;
    min-height: 30%;
    height: auto;
    width: 100%;
    background: transparent;
    display: block;
    flex-direction: row;
    margin-bottom: 2%;
}

.PersonelInfo
{
    width:100%;
    height: 100%;
    margin-top: 5%;
    color: white;
}

.JobDescription
{
    margin-top: 2%;
    font-size:1.5rem;
    text-align: left;
    margin-bottom: 2%;
}

.FeatureDivContainer
{
    width: 100%;
    display: flex;
}

.FeatureDiv
{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.FactsDiv
{
    background: transparent;
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.AboutPageDiv::-webkit-scrollbar {
    width: 0;
}

.AboutPageDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media(max-width: 1000px)
{
    .AboutPageInnerDiv {
        flex-direction: column;     
    }

    .AboutInfoDiv,
    .FactsDiv {
        height: auto;
        width: 100%;
        margin-bottom: 2%;
        display: block;
    }
    .AboutDescription
    {
        font-size: 1.1rem;
    }
}