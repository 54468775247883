.navbarDiv
{
    width: 20%;
    height: 100%;
    max-height: 100vh !important;
    background: #203232;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    overflow: scroll;
    margin: 0 !important;
}

.navbarDiv::-webkit-scrollbar {
    width: 0;
}

.navbarDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media (max-width: 1000px)
{
    .navbarDiv
    {
        width: 30%;
    }
}