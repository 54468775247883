.CVPageDiv
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background:rgba(255,255,255,0.4);
}

.CVPageInnerDiv
{
    width: 90%;
    height: 90%;
    overflow: scroll;
}

.CVPageHeader
{
    font-size: 2rem;
    margin: 1rem;
    margin-top: 2%;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
    margin-bottom: 1%;
}

.CVDownloadLink
{
    color: #0275d8;
}

.CVDownloadLink:hover
{
    color: red;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}

.CVFeatureDivContainer
{
    height: 100%;
    display: flex;
    flex-direction: row;
}

.CVFeatureDiv
{
    width: 50%;
    height: 90%;
    display: inline-block;
    background: transparent;
}

.CVPageInnerDiv::-webkit-scrollbar {
    width: 0;
}

.CVPageInnerDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media(max-width: 750px)
{
    .CVFeatureDivContainer
    {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .CVFeatureDiv
    {
        display:block;
        width: 100%;
    }
}