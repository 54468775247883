.Row
{
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: 2rem;
    padding: 0 2rem;
    justify-content: space-evenly;
}

@media (max-width: 1000px)
{
    .Row
    {
        padding: 0 1rem;
        justify-content: space-between;  
    }
}