.ContactButton
{
    width: 15%;
    height: 2rem;
    display: block;
    margin: 0 auto;
    margin-top: 2rem;
    color: white;
    background: #0275d8;
    border: 2px solid salmon;
    border-radius: 5px;
    font-size: 1.2rem;
}

.ContactButton:hover
{
    cursor: pointer;
    background: red;
    transition: 0.6s ease-in-out;
}

@media(max-width: 1000px)
{
    .ContactButton
    {
        width: 40%;
        margin: 1rem auto;
    }
}