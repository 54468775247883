.FeatureDiv
{
    width: 50%;
    display: inline-block;
    flex-direction: row;
    text-align: left;
    background: transparent;
    margin: 1% 0;
}

.FeatureIcon
{
    font-size: 1.3rem;
    font-weight: 900;
    color:gold;
    display: inline;
}

.FeatureName
{
    color:white;
    font-size: 1.2rem;
    font-weight: 600;
    font-weight: bold;
    display: inline;
}

.FeatureLabel
{
    font-size: 1.2rem;
    display: inline;
    color:white;
    font-weight: 400;
}
@media (max-width:1000px)
{
    .FeatureName
    {
        font-size: 1rem;
        height: auto;
    }

    .FeatureLabel
    {
        font-size: 1rem;
        height: auto;
    }

    .FeatureDiv
    {
        display: block;
        height: auto;
        margin: 1rem 0;
    }
}