.PageDiv
{
    width: 80%;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
    margin-left: auto;
    display: inline-block;
}

.PageDiv::-webkit-scrollbar {
    width: 0;
}

.PageDiv::-webkit-scrollbar-thumb {
    background-color: transparent;
}

@media (max-width: 1000px)
{
    .PageDiv
    {
        width: 70%;
    }
}