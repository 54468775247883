.PersonelProfile
{
    width: 8rem;
    height: 8rem;
    background: url("../../../assets/Images/ProfileImage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    margin-top:3rem;
}

@media (max-width: 1000px)
{
    .PersonelProfile
    {
        margin-top: 2rem;
        width: 6rem;
        height: 6rem;
    }
}