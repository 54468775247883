.InputLabel
{
    color: white;
    font-size: 1.2rem;
    margin: 1rem 0;
}

@media (max-width: 1000px)
{
    .InputLabel
    {
        font-size: 1rem;
    }
}