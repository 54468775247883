.LanguageBar
{
    font-size: 1.2rem;
    background-color: transparent;
    color: white;
    border: none;
}

.LanguageBar:hover
{
    color: red;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.active
{
    color: red;
    cursor: default !important;
}