* 
{
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.errorPageContainer {
    text-align: center;
    margin: 0;
    padding: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../assets/Images/horizon.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;   
}

.header
{
    font-size: 5em;
    color: white;
}

.info 
{
    font-size:2em;
    font-weight: 700;
    margin-top: 2rem;
    color: white;
}

.text
{
    margin:1em;
    font-size: 1.5em;
    color:white;
}

.asterix
{
    margin:0;
    padding:0;
    font-size: 2em;
    color:white;
}

.last_asterix
{
    margin-top: 0;
    font-size:2em;
    margin-bottom:1em;
    color:white;
}

.button
{
    margin-top: 0.2em;
    margin-bottom: 2rem;
    color:white;
    font-size:1.1em;
    font-weight: 600;
    padding: 0.8em;
    background-color:#066ED5;
    border-radius: 10px;
}

.button:hover
{
    background-color: red;
    transition: 0.4s all ease-in-out;
    cursor: pointer;
}

@media (max-width: 450px) {
    .errorPageContainer 
    {   
        height: 130vh;
    }
  }