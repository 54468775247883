.ProjectDiv
{
    width: 20%;
    height: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AppName
{
    font-size: 1.5rem;
    color: gold;
    font-weight: bold;
}

.AppDescription
{
    font-size: 1rem;
    color:white;
    font-weight: bold;
}

@media(max-width: 1000px)
{
    .ProjectDiv
    {
        width: 10rem;
        height: 10rem;
        margin: 1rem;
    }

    .AppName
    {
        font-size:1.2rem;
    }
}