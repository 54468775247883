.PageIconDiv
{
    width: 80%;
    margin: 0 auto;
}

.PageLink
{
    text-decoration: none;
    color: white;
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
}

.PageLink:hover
{
    color: gold;
    transition: 0.6s ease-in-out;
    cursor: pointer;
}

.PageInfo
{
    margin: 0 1rem;
}

.active
{
    color: gold;
    cursor: default !important;
}

@media (max-width: 1200px)
{
    .PageLink
    {
        font-size: 1rem;
    }
    .PageInfo
    {
        margin: 0 0.5rem;
    }
}