.firstBlogInfoDiv
{
    width: 80%;
    margin: 0 auto; 
    margin-top: 5rem;
}

.BlogInfoDiv
{
    width: 80%;
    margin: 0 auto; 
}

.BlogInfo
{
    color: white;
    font-size: 1.2rem;
}

.Developer
{
    color: gold;
}

@media (max-width: 500px) {
    .BlogInfo
    {
        font-size: 1rem;
    }
    .firstBlogInfoDiv
    {
        margin-top: 2rem;
    }
}